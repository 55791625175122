



















import { defineComponent, ref, watch, computed } from 'vue-demi';
import { partsDb } from '@/db/PartsDb';
import { usePartsStore } from '@/db/partsStore';
import { Part } from '@project/shared';

export default defineComponent({
  props: {
    partId: {
      type: String,
      default: '',
    },
    codeAmos: {
      type: String,
      default: '',
    },
    countVariants: {
      type: Number,
      default: 0,
    },
  },

  setup(props, { emit }) {
    const parts = usePartsStore();
    const part = ref<Part>();
    const picture = ref<string>();

    watch(
      () => props,
      async () => {
        if (props.partId || props.codeAmos) {
          part.value = props.partId
            ? await parts.getByMeshId(props.partId)
            : await parts.getByCodeAmos(props.codeAmos);

          if (part.value) {
            partsDb.getFile(`previews/${part.value?.modelMetadata?.meshId}.png`).then((file) => {
              if (file) picture.value = URL.createObjectURL(file);
            });
          }
        } else {
          part.value = picture.value = undefined;
        }
      },
      {
        immediate: true,
        deep: true,
      },
    );

    const infos = computed(() => {
      return part.value && part.value.modelMetadata;
    });

    function onClick() {
      emit('click', part.value);
    }

    return {
      part,
      infos,
      picture,
      onClick,
    };
  },
});
