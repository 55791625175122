









































































import { defineComponent, ref, onMounted, computed, onBeforeUnmount, watch } from 'vue-demi';
import { ViewerLogic } from './ViewerLogic';
import { Part } from '@project/shared';
import { usePartsStore } from '@/db/partsStore';
import { partsDb } from '@/db/PartsDb';
import { useRouter } from '@/router';
import ViewerObject from './ViewerObject.vue';
import partsOptions from './options.json';

export default defineComponent({
  components: { ViewerObject },
  props: {
    modelUrl: {
      type: String,
      required: true,
    },
    hdrUrl: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const canvasRef = ref<HTMLCanvasElement | null>(null);
    const fpsRef = ref<HTMLDivElement | null>(null);
    const loadingRef = ref<HTMLDivElement | null>(null);
    const viewerLogic = new ViewerLogic();
    const parts = usePartsStore();
    const router = useRouter();
    const variants = partsOptions as Record<string, string[]>;

    const group = ref('VEHICLE');
    const display = ref('');
    const displayOptions = ref<string[]>([]);

    const currentPart = ref<Part>();
    const meshId = computed(() => {
      return currentPart.value && currentPart.value.modelMetadata?.meshId;
    });

    const partVariations = ref<string[]>();

    function onOverPart(partId?: string) {
      if (!partId) return;
      currentPart.value = parts.getByMeshId(partId);
    }

    function onOutPart() {
      currentPart.value = undefined;
    }

    const countVariants = computed(() => {
      const id = currentPart.value?.modelMetadata?.meshId;
      return id ? variants[id]?.length : 0;
    });

    function onClickPart(partId?: string) {
      if (!partId) return;
      currentPart.value = parts.getByMeshId(partId);

      if (variants[partId] && variants[partId].length) {
        partVariations.value = variants[partId];
        onOutPart();
        return;
      }

      if (currentPart.value) {
        emit('select', currentPart.value);
      }
    }

    viewerLogic.events.on<string>('over', onOverPart);
    viewerLogic.events.on<string>('out', onOutPart);
    viewerLogic.events.on<string>('click', onClickPart);

    onMounted(async () => {
      if (canvasRef.value) {
        group.value = (router.currentRoute.query.group as string) || 'VEHICLE';

        const parts = await partsDb.getPartsMeshes();
        displayOptions.value = await viewerLogic
          .mount(canvasRef.value, fpsRef.value, loadingRef.value)
          .setElements({
            model: props.modelUrl,
            reflectionTexture: props.hdrUrl,
            parts,
          });

        viewerLogic.setGroup(group.value);
      }
    });

    onBeforeUnmount(() => {
      viewerLogic.events.off<string>('over', onOverPart);
      viewerLogic.events.off<string>('out', onOutPart);
      viewerLogic.events.off<string>('click', onClickPart);
      viewerLogic.destroy();
    });

    function onClickGroup() {
      group.value = group.value === 'VEHICLE' ? 'CONTROL_VAN' : 'VEHICLE';
      viewerLogic.setGroup(group.value);
      display.value = '';

      router.replace({
        name: 'home',
        query: {
          group: group.value,
        },
      });
    }

    watch(display, (value) => {
      viewerLogic.setDisplay(value);
    });

    function onClickRoot() {
      emit('select', {
        codeAmos: 'P00-221-78',
      });
    }

    function onOverRoot() {
      currentPart.value = parts.getByCodeAmos('P00-221-78');
    }

    function onOutRoot() {
      currentPart.value = undefined;
    }

    return {
      canvasRef,
      fpsRef,
      loadingRef,
      currentPart,
      meshId,
      group,
      display,
      displayOptions,
      onClickGroup,
      partVariations,
      onClickPart,
      countVariants,
      onClickRoot,
      onOverRoot,
      onOutRoot,
    };
  },
});
