import {
  ActionEvent,
  ActionManager,
  ArcRotateCamera,
  AssetContainer,
  Color3,
  Color4,
  Engine,
  ExecuteCodeAction,
  HDRCubeTexture,
  HemisphericLight,
  HighlightLayer,
  ILoadingScreen,
  Mesh,
  Scene,
  SceneLoader,
  Vector3,
} from '@babylonjs/core';
import mitt from 'mitt';
import '@babylonjs/loaders';
import { Part } from '@project/shared';

export interface LatchItem {
  id: string;
  title: string;
}

export interface SceneElements {
  model: string;
  reflectionTexture?: string;
  parts: Part[];
}
export class ViewerLogic {
  public canvas!: HTMLCanvasElement;
  public fpsDiv?: HTMLDivElement | null;
  public engine!: Engine;
  public scene!: Scene;
  public camera!: ArcRotateCamera;
  public glow!: HighlightLayer;
  public events = mitt();
  public model!: AssetContainer;
  public reflectionTexture!: HDRCubeTexture;

  public groups: Record<string, string[]> = {};
  public displays: Record<string, string[]> = {};

  public currentGroup = 'VEHICLE';
  public currentDisplay?: string;

  private onResize = this._onResize.bind(this);

  public mount(
    canvas: HTMLCanvasElement,
    fpsDiv?: HTMLDivElement | null,
    loadingDiv?: HTMLDivElement | null,
  ) {
    this.canvas = canvas;
    this.fpsDiv = fpsDiv;

    this.engine = new Engine(canvas);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    this.engine.loadingScreen = new CustomLoadingScreen('', loadingDiv);
    this.engine.loadingScreen.displayLoadingUI();

    this.scene = new Scene(this.engine);
    this.scene.clearColor = new Color4(0.1, 0.1, 0.1);

    new HemisphericLight('light', new Vector3(0, 1, 0), this.scene);

    this.camera = new ArcRotateCamera(
      'camera',
      -Math.PI / 2,
      Math.PI / 2.5,
      5,
      new Vector3(0, 1, 0),
      this.scene,
    );
    this.camera.setPosition(new Vector3(-10, 3, -10));
    this.camera.attachControl(canvas, true);
    this.camera.lowerRadiusLimit = 6;
    this.camera.upperRadiusLimit = 10;

    this.glow = new HighlightLayer('glow', this.scene);
    this.glow.blurHorizontalSize = 0.1;
    this.glow.blurVerticalSize = 0.1;

    this.engine.runRenderLoop(() => this.render());
    window.addEventListener('resize', this.onResize);

    return this;
  }

  public async setElements({ model, reflectionTexture, parts }: SceneElements) {
    if (reflectionTexture) this.setHDR(reflectionTexture);

    await this.setModel(model);

    if (parts && parts.length) {
      for (const part of parts) {
        const metadata = part.modelMetadata;

        if (metadata?.group) {
          const group = this.groups[metadata.group] || (this.groups[metadata.group] = []);
          group.push(metadata.meshId);
        }

        if (metadata?.display && metadata.display !== 'Van') {
          const display = this.displays[metadata.display] || (this.displays[metadata.display] = []);
          display.push(metadata.meshId);
        }
      }
    }

    this.setGroup(this.currentGroup);

    this.engine.loadingScreen.hideLoadingUI();

    return this.getDisplays();
  }

  public setGroup(name: string) {
    this.currentGroup = name;
    this.currentDisplay = undefined;
    this.showElements();
  }

  public setDisplay(name?: string) {
    this.currentDisplay = name;
    this.showElements();
  }

  public showElements() {
    for (const mesh of this.model.meshes) {
      const meshId = mesh.name.split('.')[0];
      if (meshId === '__root__') continue;

      if (!this.groups[this.currentGroup].includes(meshId)) {
        mesh.setEnabled(false);
        continue;
      }

      if (this.currentDisplay && !this.displays[this.currentDisplay].includes(meshId)) {
        mesh.setEnabled(false);
        continue;
      }

      mesh.setEnabled(true);
    }
  }

  public getDisplays() {
    return Object.keys(this.displays);
  }

  private setModel(url: string): Promise<void> | void {
    if (this.model) {
      this.model.dispose();
    }

    if (!url) {
      return;
    }

    return new Promise((resolve, reject) => {
      SceneLoader.LoadAssetContainer(
        '',
        url,
        this.scene,
        (container) => {
          this.model = container;
          this.model.addAllToScene();

          const plexiglassMaterial = this.model.materials.find((mat) => mat.name === 'Plexiglass');
          if (plexiglassMaterial) {
            for (const meshId in plexiglassMaterial.meshMap) {
              if (plexiglassMaterial.meshMap[meshId])
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                plexiglassMaterial.meshMap[meshId]!.visibility = 0.5;
            }
          }

          this.setupEvents();
          resolve();
        },
        undefined,
        (scene, message, error) => {
          reject(error);
        },
        '.glb',
      );
    });
  }

  private setHDR(url: string | null) {
    if (this.reflectionTexture) {
      this.scene.environmentTexture = null;
      this.reflectionTexture.dispose();
    }

    if (!url) {
      return;
    }

    this.reflectionTexture = this.scene.environmentTexture = new HDRCubeTexture(
      url,
      this.scene,
      128,
      false,
      true,
      false,
      true,
    );

    return this;
  }

  private setupEvents() {
    for (const mesh of this.model.meshes) {
      mesh.actionManager = new ActionManager(this.scene);

      mesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, (event) => {
          this.events.emit('over', this.getPartId(event.source.id));
          this.getMeshesFromEvent(event, this.model).forEach((item) => {
            this.glow.addMesh(item as Mesh, Color3.White());
          });
        }),
      );

      mesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, (event) => {
          this.events.emit('out', this.getPartId(event.source.id));
          this.getMeshesFromEvent(event, this.model).forEach((item) => {
            this.glow.removeMesh(item as Mesh);
          });
        }),
      );

      mesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, (event) => {
          this.events.emit('click', this.getPartId(event.source.id));
        }),
      );
    }
  }

  private removeEvents() {
    if (this.model && this.model.meshes.length) {
      for (const mesh of this.model.meshes) {
        mesh.actionManager?.dispose();
      }
    }
  }

  public getPartId(name: string) {
    return name.split('.')[0];
  }

  private getMeshesFromEvent(event: ActionEvent, container: AssetContainer) {
    const target = event.source as Mesh;
    return container.meshes.filter(
      (item) => this.getPartId(item.id) === this.getPartId(target.id) && item instanceof Mesh,
    );
  }

  private render() {
    this.scene.render();
    if (this.fpsDiv) {
      this.fpsDiv.innerHTML = this.engine.getFps().toFixed();
    }
  }

  private _onResize() {
    this.engine.resize();
  }

  public destroy() {
    this.removeEvents();
    if (this.camera) this.camera.dispose();
    if (this.glow) this.glow.dispose();
    if (this.reflectionTexture) this.reflectionTexture.dispose();
    if (this.model) this.model.dispose();
    if (this.scene) this.scene.dispose();
    if (this.engine) this.engine.dispose();
    window.removeEventListener('resize', this.onResize);
  }
}

class CustomLoadingScreen implements ILoadingScreen {
  public loadingUIBackgroundColor = '#000';

  constructor(public loadingUIText: string, public domElement?: HTMLDivElement | null) {}

  public displayLoadingUI() {
    if (this.domElement) this.domElement.style.display = 'block';
  }

  public hideLoadingUI() {
    if (this.domElement) this.domElement.style.display = 'none';
  }
}
